<template>
  <v-row justify="center" class="primary-text">
    <v-col cols="10">
      <v-card class="mb-5">
        <v-container>
          <v-form v-model="isFormValid" class="pl-5 pr-5" v-on:submit.prevent="submitForm()">
            <div class="headline pb-1">UVKYC Features Selected</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  :disabled="this.currentJourneyType== 'UVKYC'"
                  v-model="localConfig.uvkycIntroConf.enablePartialDataSubmit"
                  label="Enable Partial data submit for the call (This will mark the status for the call as completed)"
                ></v-checkbox>
                <v-checkbox
                  :disabled="this.currentJourneyType== 'UVKYC'"
                  v-model="localConfig.uvkycIntroConf.enablePassiveLivelinessCheck"
                  label="Do you want to enable passive liveliness check?"
                ></v-checkbox>
                <v-text-field
                  v-if="localConfig.uvkycIntroConf.enablePassiveLivelinessCheck"
                  v-model.number="localConfig.uvkycIntroConf.livelinessThreshold"
                  :rules="rules.thresholdLimit"
                  style="padding-top:0px;"
                  label="Passive liveliness acceptance threshold from 0 to 1 (default is 0.6)"
                  ref="variable_LivelinessThreshold"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <v-checkbox
                  :disabled="this.currentJourneyType == 'UVKYC'"
                  v-model="localConfig.uvkycIntroConf.endWithCallbackOnSuccess"
                  label="End unassisted journey with a callback on success (This will prevent the user from connecting with the bank if the unassisted journey is successful)"
                ></v-checkbox>
                <v-checkbox
                  :disabled="this.currentJourneyType== 'UVKYC'"
                  v-model="localConfig.uvkycIntroConf.enableDeepfakeCheck"
                  style="margin-top:0px;"
                  label="Do you want to enable deepfake check? (This will abort the session for the user if our system detects spoofing)"
                ></v-checkbox>
                <v-checkbox
                  v-model="localConfig.uvkycIntroConf.randomizeQuestions"
                  style="margin-top:0px;"
                  label="Enable randomization of questions"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div>
              <div class="headline pb-1">Domain Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-text-field v-model="localConfig.uvkycIntroConf.customDomain" label="Add Custom domain (https://abc.com/)"
                        :rules="rules.customDomainMatch" ref="variable_customDomain">
                    </v-text-field>
                </v-col>
              </v-row>
              <div class="headline pb-1">Basic Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model.number="localConfig.uvkycIntroConf.expiry"
                    :rules="rules.timeCheck"
                    label="Call expiry in hours"
                    ref="variable_CallExpiry"
                  ></v-text-field>
                  <v-checkbox
                    v-model="localConfig.uvkycIntroConf.enableDocumentNameMatch"
                    label="Do you want to enable name match with document?"
                  ></v-checkbox>
                  <v-text-field
                    v-if="localConfig.uvkycIntroConf.enableDocumentNameMatch"
                    v-model.number="localConfig.uvkycIntroConf.documentNameMatchThreshold"
                    :rules="rules.thresholdLimit"
                    style="padding-top:0px;"
                    label="Document name match acceptance threshold from 0 to 1 (default is 0.5)"
                    ref="variable_DocumentNameMatchThreshold"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    v-model.number="localConfig.uvkycIntroConf.documentLivelinessThreshold"
                    :rules="rules.thresholdLimit"
                    label="Document liveliness acceptance threshold from 0 to 1 (default is 0.5)"
                    ref="variable_DocumentLivelinessThreshold"
                  ></v-text-field>
                </v-col>
              </v-row>
                
              <div class="headline pb-1">Language Options</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                 <v-checkbox
                    @change="resetLanguages()"
                    v-model="localConfig.uvkycIntroConf.enableRuntimeTranslate"
                    label="Do you want to enable runtime translate?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" v-if="localConfig.uvkycIntroConf.enableRuntimeTranslate">
                  <v-select
                    v-model="localConfig.uvkycIntroConf.languagesOptions"
                    :items="languages"
                    label="Select"
                    multiple
                    chips
                    hint="What are the target languages"
                    persistent-hint
                  ></v-select>
                </v-col>
              </v-row>
              <div class="headline pb-1 mt-5">Location Configurations</div>
              <v-divider class="ml-5 mb-3"></v-divider>
              <v-row justify="center">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.uvkycIntroConf.countryWhitelisting"
                    label="Do you want to have country whitelisting?"
                  ></v-checkbox>
                  <v-autocomplete
                    v-if="localConfig.uvkycIntroConf.countryWhitelisting"
                    v-model="localConfig.uvkycIntroConf.allowedCountries"
                    :items="countryList"
                    ref="variable_AllowedCountries"
                    :rules="rules.checkEmpty"
                    item-text="Country"
                    item-value="code"
                    chips
                    label="Select the countries to be whitelisted"
                    multiple
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-checkbox
                    v-model="localConfig.uvkycIntroConf.disableVPNCheck"
                    label="Do you want to disable VPN Check?"
                  ></v-checkbox>
                </v-col>
              </v-row>

            <div class="headline pb-1 mt-5">User Experience Configurations</div>
            <v-divider class="ml-5 mb-3"></v-divider>
            <v-row>
              <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                <div style="background: #d0d0d0">
                  <v-img class="ma-4" aspect-ratio="3.4" :src="imageLogo" />
                </div>
                <v-file-input
                  id="fileUpload"
                  @click:clear="clearImage()"
                  show-size
                  @change="changeImage"
                  :rules="rules.fileUpload"
                  hint="Only Images (JPEG, JPG, PNG) with size < 200Kb"
                  :persistent-hint="true"
                  accept="image/png, image/jpeg, image/jpg"
                  label="Change Image"
                ></v-file-input>
              </v-col>
            </v-row>

            </div>
            <div class="text-right">
              <v-btn class="ma-0" type="submit" color="primary" :disabled="!isFormValid">Update</v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-col>
    <v-dialog v-model="alertMessage" persistent max-width="550">
      <v-card>
        <v-card-text>
          <p class="subtitle-1 pt-4 mb-0 pb-0">{{ message }}</p>
          <v-spacer></v-spacer>
          <div class="text-right">
            <v-btn dark small color="signzy_color" @click="alertMessage = false"
              >Ok</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { uploadFile, downloadFile } from "@/flux/persist.flux";
import {
  base_url,
  end_points,
  vcip_end_points,
  host,
} from "@/config";
import axios from "axios";
let eventBus = window.eventBus;
let store = window.store;
export default {
  data: () => ({
    isFormValid: true,
    alertMessage: false,
    message: "",
    customerId: '',
    currentFlow: "",
    localConfig: {
      uvkycIntroConf:{
        isRecording: true
      }
    },
    isRecording: true,
    imageLogo: "",
    imagePersistUrl: "",
    countryList: require("@/assets/country-data.json"),
    imageCleared: false,
    languages: ["HINDI", "KANNADA", "BENGALI", "GUJARATI", "MALAYALAM", "MARATHI", "PUNJABI", "TAMIL", "TELUGU"],
    rules: {
      fileUpload : [
        (value) =>
          !value ||
          value.type === "image/jpg" || value.type === "image/jpeg" || value.type === "image/png" ||
          "Only jpeg, jpg and png files are allowed",
        (value) =>
          !value ||
          value.size < 200000 ||
          "Image size should be less than 200KB!"
      ],
      onlySpaceCheck: [
        (value) => !/^\s+$/.test(value) || "Please enter valid value, cannot take only spaces"
      ],
      timeCheck : [
        (value) => (value !== null && value !== undefined && value !== '') || "Cannot be left empty!",
        (value) => !/\D/.test(value) || "Can take only numeric values",
        (value) => !(parseInt(value) < 1 )|| "Cannot be less than 1 hour",
        (value) => !(parseInt(value) > 48 )|| "Cannot be more than 48 hours"
      ],
      thresholdLimit : [
        (value) => (value !== null && value !== undefined && value !== '') || "Cannot be left empty!",
        (value) => !isNaN(value) || "Can take only numeric(floating) values",
        (value) => !(parseFloat(value) < 0 )|| "Cannot be less than 0",
        (value) => !(parseFloat(value) > 1 )|| "Cannot be more than 1"
      ],
      checkEmpty : [
        (value) =>
          !(value.length < 1) ||
          "Minimum one country is required for whitelisting!"
      ],
      
      specialCharsCheck: [
        (value) => {
          if(value && value != ''){
            if(value.match(/[()~`<>*]/)){
              return "Cannot take  ( ) ~ ` < > * in input"
            }
          }
          return true;
        }
      ],
      URL : [
        (value) => {
          if (value === "" || !value) {
            return true;
          }
          let regex = new RegExp(
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
          );
          if (!value.match(regex)) {
            return "Enter a valid image URL!";
          }
          return true;
        }
      ],
      
      customDomainMatch : [
          (value) => {
            if (value === "" || !value) {
              return true;
            }
            let regex = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi);
            if (!value.match(regex)) {
              return "Enter a valid custom domain, like (https://abc.com/)!"
            }
            return true;
          }
        ]
    },
    localCopy: {},
    updatedSuccessfully: false,
  }),
  methods: {
    resetLanguages() {
      this.localConfig.uvkycIntroConf.languagesOptions = [];
    },
    clearImage() {
      this.imageCleared = true;
      this.imageLogo =
        this.localConfig.uvkycIntroConf.imageLogo || require("@/assets/signzy.png");
    },

    async changeImage(file) {
      if (!this.imageCleared) {
        if (
          file &&
          file.size < 200000 &&
          (file.type == "image/jpeg" ||
            file.type == "image/jpg" ||
            file.type == "image/png")
        ) {
          let formData = new FormData();
          formData.append("file", file);
          formData.append("ttl", "3 years");
          this.imagePersistUrl = await uploadFile({
            url: base_url + end_points.upload_files,
            data: formData,
            token: this.$store.getters.userData.token,
            secretKey: this.$store.getters.envValues.secretKey
          });
          if (this.imagePersistUrl) {
            let imageDataResp = await downloadFile(this.imagePersistUrl);
            this.imageLogo = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`
            this.imageCleared = false;
          } 
        }
      } else {
        this.imageCleared = false;
      }
    },

    validateFields(ref, typeOfValidation) {
      if (typeOfValidation === "customDomainMatch") {
        if (ref.value === "" || ref.value.length === 0) {
          return false;
        }
        if (!this.urlChecker(ref.value)) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "numeric") {
        if (!ref.value || !ref.value.toString().length) {
          return true;
        }
        if (/\D/.test(ref.value)) {
          return true;
        }
        if (ref.value < 1) {
          return true
        }
        if (ref.value > 60) {
          return true
        }
        return false;
      } else if (typeOfValidation === "threshold") {
        if (isNaN(ref.value)) {
          return true;
        }
        if (parseFloat(ref.value) < 0) {
          return true;
        }
        if (parseFloat(ref.value) > 1) {
          return true;
        }
        return false;
      } else if (typeOfValidation == "specialCharsCheck") {
        if (ref.value && ref.value.match(/[()~`<>*]/)) {
          return true;
        }
        if (ref.value && !ref.value.replace(/\s/g, '').length) {
          return true;
        }
        return false;
      } else if (typeOfValidation === "checkEmpty") {
        if (!ref.value || ref.value.length < 1) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    urlChecker(url) {
      let regex = new RegExp(
        /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
      );
        if (!url.match(regex)) {
          return false;
        }
      return true;
    },
    getFlowData(){
      if(this.currentFlow != "default"){
        if(!this.config.multiflow[this.currentFlow]){
          //deep copy
          let {multiflow , ...dummy} = {...this.config};
          this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(dummy));
        }
        this.localConfig = this.config.multiflow[this.currentFlow];
        if(!this.localConfig.uvkycIntroConf) {
            this.localConfig.uvkycIntroConf = {};
        }
        this.localCopy.uvkycIntroConf = JSON.parse(JSON.stringify(this.localConfig.uvkycIntroConf));   // creating a deep copy of config for local referencing
      } else{
        this.localConfig = this.config;
        this.localCopy.uvkycIntroConf = JSON.parse(JSON.stringify(this.config.uvkycIntroConf));   // creating a deep copy of config for local referencing
      }
    },
    showAlert(message) {
      this.message = message;
      this.alertMessage = true;
    },
    highLighter(element) {
      // element passed is a ref
      element.focus();
      element.blur();
    },
    submitForm() {
      //Cheking imageLogo
      if (this.imageLogo) {
        this.localConfig.uvkycIntroConf.imageLogo = this.imagePersistUrl;
      }

      if (this.localConfig.uvkycIntroConf.customDomain && this.validateFields(this.$refs.variable_customDomain , "customDomainMatch")) {
        this.highlighter(this.$refs.variable_customDomain);
      } else if (this.validateFields(this.$refs.variable_CallExpiry, "numeric")) {
        console.log("callExpity");
        this.highLighter(this.$refs.variable_CallExpiry);
      } else if (this.localConfig.uvkycIntroConf.enablePassiveLivelinessCheck && this.validateFields(this.$refs.variable_LivelinessThreshold, "threshold")) {
        console.log("liveliness");
        this.highLighter(this.$refs.variable_LivelinessThreshold);
      } else if (this.validateFields(this.$refs.variable_DocumentLivelinessThreshold, "threshold")) {
        console.log("documentLiveliness");
        this.highLighter(this.$refs.variable_DocumentLivelinessThreshold);
      } else if (this.localConfig.uvkycIntroConf.enableDocumentNameMatch && this.validateFields(this.$refs.variable_DocumentNameMatchThreshold, "threshold")) {
        console.log("documentNameMatch");
        this.highLighter(this.$refs.variable_DocumentNameMatchThreshold);
      } else if (this.localConfig.uvkycIntroConf.countryWhitelisting && this.validateFields(this.$refs.variable_AllowedCountries, "checkEmpty")){
        this.highLighter(this.$refs.variable_AllowedCountries);
      } else {
            if(this.currentFlow != "default"){
              this.config.multiflow[this.currentFlow] = JSON.parse(JSON.stringify(this.localConfig));
            } else{
              this.config = JSON.parse(JSON.stringify(this.localConfig))
            }
            eventBus.$emit("updateConfig", ["uvkycIntroConf", "languagesOptions"]);
            //Setting updatedSuccessfully as the flag sent from the event
            eventBus.$on("updatedSuccessfully", (flag)=>{
                this.updatedSuccessfully = flag;
                this.$nextTick(()=> {
                  this.getFlowData();
                })
            });
        }

    },
  },
  beforeRouteUpdate (to, from, next) {
      this.currentFlow = to.params.id;
      if(this.currentFlow == undefined){
        this.currentFlow = "default";
      }
      this.getFlowData();
      next()
  },
  beforeRouteLeave (to, from, next) {
    //If not updatedSuccessfully then replace it with the localCopy that was created at the start
    if(!this.updatedSuccessfully){
      //Checking if it's a multiflow setting, then update the main config file accordingly
      if(this.currentFlow != "default"){
        //Using deep copy to reset the config file to it's orginal state before leaving the route
        this.config.multiflow[this.currentFlow].uvkycIntroConf = JSON.parse(JSON.stringify(this.localCopy.uvkycIntroConf ));
      } else{
        this.config.uvkycIntroConf = JSON.parse(JSON.stringify(this.localCopy.uvkycIntroConf));
      }
    }
    next()
  },
  updated(){
      setTimeout(() => {
        this.getFlowData()
      },0);
  },

  created() {
    if (!this.$store.getters.userData || !sessionStorage.getItem('userId')) {
      if(this.$store.getters.envValues?.client == 'citi') {
        this.$router.push({ path: '/custom/citi/errorpage', query: { errCode: 401 }});
      } else {
        this.$router.push("/");
      }
    } else {
       let userData = this.$store.getters.userData;
       if (userData.customerData) {
        this.customerId = userData.customerData.id;
       } else {
        this.customerId = userData.userId
       }
       axiosInstance.defaults.headers.common["Authorization"] = userData.token;


       if (userData.userId) {
        axiosInstance
          .get(end_points.get_customer_data(this.customerId))
          .then((customerData) => {
            if(customerData && customerData.data && customerData.data.config && customerData.data.config.uvkycIntroConf){
              if(customerData.data.config.uvkycIntroConf) {
                  Object.assign(this.config.uvkycIntroConf, customerData.data.config.uvkycIntroConf);
              }else {
                this.config.uvkycIntroConf = {};
              }  
            }else{
              if(this.$store.getters.userData.config.uvkycIntroConf) {
                  Object.assign(this.config.uvkycIntroConf, this.$store.getters.userData.config.uvkycIntroConf);
              }else {
                this.config.uvkycIntroConf = {};
              }  
            }

            this.currentFlow = this.$route.params.id;
            
            if(this.currentFlow == undefined){
              this.currentFlow = "default";
            }
            
            this.getFlowData();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
  
    watch: {
      //Display logo only if given in UVKYC User Experience Configuration
    localConfig: async function () {
      this.imageLogo = this.localConfig?.uvkycIntroConf?.imageLogo;

      if (!this.imageLogo || this.imageLogo?.length == 0) {
        require("@/assets/signzy.png")
      }
      else if (!this.imageLogo.includes('base64,')) {
        this.imagePersistUrl = this.imageLogo;
        let imageDataResp = await downloadFile(this.imageLogo)
        this.imageLogo = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`
      }
    },
  },  
  props: ["config", "currentJourneyType"],
};
</script>
<style>
.fa-check:before{
  margin-top: 0.12rem;
  margin-left: 0.12rem;
  font-size: small;
}
.fa-exclamation-triangle:before {
  margin-top: 1.22rem;
}
</style>
